// import React, { useState } from "react"
// import { Card, Button, Alert } from "react-bootstrap"
import React, { Component } from "react"
import { useAuth } from "../contexts/AuthContext"
import { Link} from "react-router-dom"
import Feed from "./Feed.js"
import firebase from "firebase"

import logo from "../assets/img/tek-logo.svg"
// import home from "../assets/img/fa/home.svg"
import list from "../assets/img/fa/list-alt.svg"
import trophy from "../assets/img/fa/trophy.svg"
// import user from "../assets/img/fa/user-alt.svg"
import faq from "../assets/img/fa/comments.svg"
import signOut from "../assets/img/fa/sign-out-alt.svg"


// export default function Dashboard() {
  // const [error, setError] = useState("")
//   const { currentUser, logout } = useAuth()
//   const history = useHistory()

//   async function handleLogout() {
//     setError("")

//     try {
//       await logout()
//       history.push("/login")
//     } catch {
//       setError("Failed to log out")
//     }
//   }

  // return (
class Dashboard extends Component{
    constructor() {
        super();
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            type: '',
            company: '',
            team: '',
            UID: '',
            points: '',
            shares: [],
            likes: [],
            comments: [],
            postToFeed: [],
            postToFeedRank: [],
            totalPoints:0,
            mostPointsName:'',
            mostPoints: 0,
            mostLikesName:'',
            mostLikes: 0,
            mostSharesName: '',
            mostShares: 0,
            mostCommentsName: '',
            mostComments: 0,
            pointsRank: [],
            sharesRank: [],
            likesRank: [],
            commentsRank: []

        };
    }
 componentDidMount(){
       const state = this.state
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                state.UID = user.uid
            } else {
                console.log('Logged out')
            }
        });
        // User points
        firebase.firestore().collection("users")
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                if (doc.data().UID === state.UID){
                    this.setState({              
                      points: doc.data().points
                    });
                }
            });
        })
        .catch((error) => {
            console.log("Error getting documents: ", error);
        });
        // User points
        firebase.firestore().collection("users")
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                if (doc.data().UID === state.UID){
                    this.setState({              
                      points: doc.data().points
                    });
                }
            });
        })
        // Check if user type is Partner
        firebase.firestore().collection("users")
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                if (doc.data().UID === state.UID){
                    this.setState({              
                      type: doc.data().type == "Partner"
                    });
                }
            });
        })
    }

render(){
    const { points } = this.state;
    const { type } = this.state;
    let leagueTable;

    if (type) {
      leagueTable = '';
    } else {
      leagueTable = <li className="nav-item">
                        <Link to="/league-table" className="nav-link align-middle px-0 text-light mb-2">
                            <i><img src={trophy} alt="L"/></i> <span className="ms-3 d-none d-sm-inline">League Table</span>
                        </Link>
                    </li>;
    }
    return (
      <div>
        <header>
        <nav className="navbar navbar-light bg-light border-bottom">
            <div className="container-fluid">
                    <img className="navbar-brand m-2" src={logo} alt="Tektronix logo" height="40px"/>
            </div>
        </nav>
    </header>
    <div className="container-fluid">
    <div className="row flex-nowrap">
        <div className="col-2 col-sm-4 col-lg-2 px-sm-2 px-0 sidebar">
            <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                    <li className="nav-item">
                      <Link to="/" className="nav-link align-middle px-0 fw-bolder bluetext mb-2">
                            <i><img src={list} alt="F"/></i> <span className="ms-3 d-none d-sm-inline">Your Feed</span>
                       </Link>
                    </li>
                    {leagueTable}
                    {/* <li className="nav-item">
                        <a href="/key-accounts.html" className="nav-link align-middle px-0 text-light mb-2">
                            <i><img src={user} alt="P"/></i> <span className="ms-3 d-none d-sm-inline">Partner Feeds</span>
                        </a>
                    </li> */}
                    <li className="nav-item">
                        <Link to="/faq" className="nav-link align-middle px-0 text-light mb-2">
                            <i><img src={faq}alt="F" /></i> <span className="ms-3 d-none d-sm-inline">FAQs</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/login" className="nav-link align-middle px-0 text-light mb-2">
                            <i><img src={signOut} alt="L"/></i> <span className="ms-3 d-none d-sm-inline">Log Out</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
        <div className="col-10 col-sm-8 col-lg-10 p-5">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-lg-9">
                        <h1>Welcome to <span className="bluetext">#TekSocial</span></h1>
                        <h2 className="h5">Your posts to share</h2>
                        <h2 className="h5">{type}</h2>
                    </div>
                    <div className="col-lg-3">
                      <div className="points-total">
                        <h1 className="bluetext">{points}</h1>
                        <p><small>Points</small></p>
                      </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid p-0">
                <Feed/>
            </div>
        </div>
    </div>
</div>
    
    <footer>
        <nav className="navbar navbar-bottom navbar-light bg-light">
            <div className="container-fluid">
                <span>BetterSharing v0.8.4(alpha)</span><a className="navbar-brand" href="https://www.betteragency.co.uk" target="_blank" rel="noreferrer">&copy;2021 Better Agency</a>
                <Link to="/admin">A</Link>
            </div>
        </nav>
    </footer>
      </div>
    )
}
}  
  // )
// }
export default Dashboard