import { Link } from "react-router-dom"
import firebase from "firebase"
import React, { Component } from 'react';
import { Button } from 'react-bootstrap'


import logo from "../assets/img/tek-logo.svg"
// import home from "../assets/img/fa/home.svg"
import list from "../assets/img/fa/list-alt.svg"
import trophy from "../assets/img/fa/trophy.svg"    
// import user from "../assets/img/fa/user-alt.svg"
import faq from "../assets/img/fa/comments.svg"
import signOut from "../assets/img/fa/sign-out-alt.svg"
// import { render } from "@testing-library/react";
// import LineGraph from "./LineGraph";

class League extends Component{
    constructor() {
        super();
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            type: '',
            company: '',
            team: '',
            UID: '',
            points: '',
            shares: [],
            likes: [],
            comments: [],
            postToFeed: [],
            postToFeedRank: [],
            totalPoints:0,
            mostPointsName:'',
            mostPoints: 0,
            mostLikesName:'',
            mostLikes: 0,
            mostSharesName: '',
            mostShares: 0,
            mostCommentsName: '',
            mostComments: 0,
            pointsRank: [],
            sharesRank: [],
            likesRank: [],
            commentsRank: []

        };
    }
 componentDidMount(){
       const state = this.state
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                state.UID = user.uid
            } else {
                console.log('Logged out')
            }
        });
        // User points
        firebase.firestore().collection("users")
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                if (doc.data().UID === state.UID){
                    this.setState({
                                    firstName: doc.data().firstName,
                                    lastName: doc.data().lastName,
                                    email: doc.data().email,
                                    type: doc.data().type,
                                    company: doc.data().company,
                                    team: doc.data().team,
                                    UID: doc.data().UID,
                                    points: doc.data().points,
                                    shares: doc.data().shares.length,
                                    likes: doc.data().likes.length,
                                    comments: doc.data().comments.length,
                                    postToFeed: doc.data().postToFeed.length
                    });
                }
            });
        })
        .catch((error) => {
            console.log("Error getting documents: ", error);
        });
        // Total points
        firebase.firestore().collection("users")
        .get()
        .then((querySnapshot) => {
            var pointsCount = 0
            var likesCount = 0
            var sharesCount = 0
            var commentsCount = 0
            var postToFeedCount = 0
            querySnapshot.forEach((doc) => {
                pointsCount    += doc.data().points
                likesCount     += doc.data().likes.length
                sharesCount    += doc.data().shares.length
                commentsCount  += doc.data().comments.length
                postToFeedCount  += doc.data().postToFeed.length
            })
            this.setState({
                totalPoints: pointsCount,
                totalLikes: likesCount,
                totalShares: sharesCount,
                totalComments: commentsCount,
                totalPostToFeed: postToFeedCount
            })
        })
        // Leaders
        firebase.firestore().collection("users").where("points", ">", 0)
        .get()
        .then((querySnapshot) => {
            // var pointsName
            // var pointsCount = 0
            // var sharesName
            // var sharesCount = 0
            // var likesName
            // var likesCount = 0
            // var commentsName
            // var commentsCount = 0
            var pointsRank = []
            var sortedPointsRank = []
            var sharesRank = []
            var sortedSharesRank = []
            var likesRank = []
            var sortedLikesRank = []
            var commentsRank = []
            var sortedCommentsRank = []
            var postToFeedRank = []
            var sortedPostToFeedRank = []
            querySnapshot.forEach((doc) => {

                // leader boards
                var n = doc.data().firstName
                var ln = doc.data().lastName
                var p = doc.data().points
                var s = doc.data().shares.length
                var l = doc.data().likes.length
                var c = doc.data().comments.length
                var f = doc.data().postToFeed.length

                pointsRank.push({n,ln,p})
                sharesRank.push({n,ln,s})
                likesRank.push({n,ln,l})
                commentsRank.push({n,ln,c})
                postToFeedRank.push({n,ln,f})

           // Sort the rank arrays highest to lowest
                sortedPointsRank = pointsRank
                    .sort(function(a, b) {
                        if (a.p === b.p) 
                            return 0;

                        return a.p > b.p ? 1 : -1;
                    })
                    .reverse()
                sortedSharesRank = sharesRank
                    .sort(function(a, b) {
                        if (a.s === b.s) 
                            return 0;

                        return a.s > b.s ? 1 : -1;
                    })
                    .reverse()
                sortedLikesRank = likesRank
                    .sort(function(a, b) {
                        if (a.l === b.l) 
                            return 0;

                        return a.l > b.l ? 1 : -1;
                    })
                    .reverse()
                sortedCommentsRank = commentsRank
                    .sort(function(a, b) {
                            if (a.c === b.c) 
                                return 0;

                            return a.c > b.c ? 1 : -1;
                    })
                    .reverse()
                sortedPostToFeedRank = postToFeedRank
                    .sort(function(a, b) {
                            if (a.f === b.f) 
                                return 0;

                            return a.f > b.f ? 1 : -1;
                    })
                    .reverse()
            })
 


            this.setState({
                pointsRank: sortedPointsRank,
                sharesRank:sortedSharesRank,
                likesRank:sortedLikesRank,
                commentsRank:sortedCommentsRank,
                postToFeedRank:sortedPostToFeedRank
            })
        })
        .catch((error) => {
        console.log("Error getting documents: ", error);
    });
    }
    rank = (e) => {
        // console.log(e.target.value)
        document.querySelectorAll('.rank.crd').forEach(function(table){
            table.classList.add('xHidden')
        })
        document.querySelectorAll('.rank.btn').forEach(function(button){
            button.classList.add('bg-secondary')
            button.classList.remove('bluebg')
        })
        switch (e.target.value){
            case "points":
                document.querySelector('.rank.points').classList.remove('xHidden')
                e.target.classList.remove('bg-secondary')
                e.target.classList.add('bluebg')
                break;
            case "shares":
                document.querySelector('.rank.shares').classList.remove('xHidden')
                e.target.classList.remove('bg-secondary')
                e.target.classList.add('bluebg')
                break;
            case "likes":
                document.querySelector('.rank.likes').classList.remove('xHidden')
                e.target.classList.remove('bg-secondary')
                e.target.classList.add('bluebg')
                break;
            case "comments":
                document.querySelector('.rank.comments').classList.remove('xHidden')
                e.target.classList.remove('bg-secondary')
                e.target.classList.add('bluebg')
                break;
            case "postToFeed":
                document.querySelector('.rank.postToFeed').classList.remove('xHidden')
                e.target.classList.remove('bg-secondary')
                e.target.classList.add('bluebg')
                break;
            default:

        }
    }

render(){
    const { 
            firstName,
            lastName,
            points,
            shares,
            likes,
            comments,
            postToFeed,
            pointsRank,
            likesRank,
            sharesRank,
            commentsRank,
            postToFeedRank
        } = this.state;
    return (
      <div>
        <header>
        <nav className="navbar navbar-light bg-light border-bottom">
            <div className="container-fluid">
                    <img className="navbar-brand m-2" src={logo} alt="Tektronix logo" height="40px"/>
            </div>
        </nav>
    </header>
    <div className="container-fluid">
    <div className="row flex-nowrap">
        <div className="col-2 col-sm-4 col-lg-2 px-sm-2 px-0 sidebar">
            <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                    {/* <li className="nav-item">
                        <a href="/dashboard.html" className="nav-link align-middle px-0 text-light mb-2 mt-2">
                            <i><img src={home} alt="D"/></i> <span className="ms-3 d-none d-sm-inline">Dashboard</span>
                        </a>
                    </li> */}
                    <li className="nav-item">
                      <Link to="/" className="nav-link align-middle px-0 text-light mb-2">
                            <i><img src={list} alt="F"/></i> <span className="ms-3 d-none d-sm-inline">Your Feed</span>
                       </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/League" className="nav-link align-middle px-0 fw-bolder bluetext mb-2">
                            <i><img src={trophy} alt="L"/></i> <span className="ms-3 d-none d-sm-inline">League Table</span>
                        </Link>
                    </li>
                    {/* <li className="nav-item">
                        <a href="/key-accounts.html" className="nav-link align-middle px-0 text-light mb-2">
                            <i><img src={user} alt="P"/></i> <span className="ms-3 d-none d-sm-inline">Partner Feeds</span>
                        </a>
                    </li> */}
                    <li className="nav-item">
                        <Link to="/faq" className="nav-link align-middle px-0 text-light mb-2">
                            <i><img src={faq}alt="F" /></i> <span className="ms-3 d-none d-sm-inline">FAQs</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/login" className="nav-link align-middle px-0 text-light mb-2">
                            <i><img src={signOut} alt="L"/></i> <span className="ms-3 d-none d-sm-inline">Log Out</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
        <div className="col-10 col-sm-8 col-lg-10 p-5">
            <div className="container-fluid p-0">
        <div className="row posts justify-content-center align-items-top">
            <div className="col py-3">
            <h1><span className="bluetext">#TekSocial</span> League Table</h1>
            <section className="pt-md-5 px-md-5">
                <h4>Overview for {firstName} {lastName}</h4>
                <div className="card p-3 shadow border-0">  
                    <div className="row align-items-center text-center justify-content-center">
                        <div className="col-lg-2">
                            <div className="row bluetext fw-bolder">
                                <h4>{points}</h4>
                            </div>
                            <div className="row">
                                <span>Points</span>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="row bluetext fw-bolder">
                                <h4>{shares}</h4>
                            </div>
                            <div className="row">
                                <span>Shares</span>
                            </div>                            
                        </div>
                        {/*<div className="col-lg-2">
                            <div className="row bluetext fw-bolder">
                                <h4>{likes}</h4>
                            </div>
                            <div className="row">
                                <span>Likes</span>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="row bluetext fw-bolder">
                                <h4>{comments}</h4>
                            </div>
                            <div className="row">
                                <span>Comments</span>
                            </div>
                        </div>*/}
                        <div className="col-lg-2">
                            <div className="row bluetext fw-bolder">
                                <h4>{postToFeed}</h4>
                            </div>
                            <div className="row">
                                <span>Posts</span>
                            </div>
                        </div>
                        {/* <div className="col-2">
                            <Link role="button" to="/" className="btn bluebg rounded-pill">Keep Sharing</Link>
                        </div> */}
                    </div>
                </div>
            </section>
            <section className="p-md-5 pt-0">
                <div className="row justify-content-center">
                    <div className="col-12 mb-3 mt-4 text-center">
                        <Button className="rounded-pill bluebg border-0 m-1 rank" value="points" onClick={this.rank}>Points</Button>
                        <Button className="rounded-pill bg-secondary border-0 m-1 rank" value="shares" onClick={this.rank}>Shares</Button>
                        {/*<Button className="rounded-pill bg-secondary border-0 m-1 rank" value="likes" onClick={this.rank}>Likes</Button>*/}
                        {/*<Button className="rounded-pill bg-secondary border-0 m-1 rank" value="comments" onClick={this.rank}>Comments</Button>*/}
                        <Button className="rounded-pill bg-secondary border-0 m-1 rank" value="postToFeed" onClick={this.rank}>Posts</Button>
                    </div>
                    <div className="col-lg-8 mb-4 rank crd points">
                        <div className="card shadow border-0 p-4">
                            <h4>Top 20 Points</h4>
                            <div>
                                <table className="table table-hover text-center m-3">
                                    <tbody>
                                            {pointsRank.slice(0, 20).map((item,index) => 
                                                <tr
                                                key={index}
                                                >
                                                    <td>
                                                        <div className="row"><p>{index + 1}</p></div>
                                                    </td>
                                                    <td><p>{item.n} {item.ln}</p></td>
                                                    <td><p className="bluetext fw-bolder">{item.p}</p></td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 mb-4 xHidden rank crd shares">
                        <div className="card shadow border-0 p-4">
                            <h4>Top 20 Shares</h4>
                            {/* <div>
                                <button className="btn bluebg rounded-pill">Total</button>
                                <button className="btn btn-light rounded-pill">Shares</button>
                                <button className="btn btn-light rounded-pill">Likes</button>
                                <button className="btn btn-light rounded-pill">Comments</button>
                            </div> */}
                            <div>
                                <table className="table table-hover text-center m-3">
                                    <tbody>
                                            {sharesRank.slice(0, 20).map((item,index) => 
                                                <tr
                                                key={index}
                                                >
                                                    <td>
                                                        <div className="row"><p>{index + 1}</p></div>
                                                    </td>
                                                    <td><p>{item.n} {item.ln}</p></td>
                                                    <td><p className="bluetext fw-bolder">{item.s}</p></td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 mb-4 xHidden rank crd likes">
                        <div className="card shadow border-0 p-4">
                            <h4>Top 20 Likes</h4>
                            {/* <div>
                                <button className="btn bluebg rounded-pill">Total</button>
                                <button className="btn btn-light rounded-pill">Shares</button>
                                <button className="btn btn-light rounded-pill">Likes</button>
                                <button className="btn btn-light rounded-pill">Comments</button>
                            </div> */}
                            <div>
                                <table className="table table-hover text-center m-3">
                                    <tbody>
                                            {likesRank.slice(0, 20).map((item,index) => 
                                                <tr
                                                key={index}
                                                >
                                                    <td>
                                                        <div className="row"><p>{index + 1}</p></div>
                                                    </td>
                                                    <td><p>{item.n} {item.ln}</p></td>
                                                    <td><p className="bluetext fw-bolder">{item.l}</p></td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 mb-4 xHidden rank crd comments">
                        <div className="card shadow border-0 p-4">
                            <h4>Top 20 Comments</h4>
                            {/* <div>
                                <button className="btn bluebg rounded-pill">Total</button>
                                <button className="btn btn-light rounded-pill">Shares</button>
                                <button className="btn btn-light rounded-pill">Likes</button>
                                <button className="btn btn-light rounded-pill">Comments</button>
                            </div> */}
                            <div>
                                <table className="table table-hover text-center m-3">
                                    <tbody>
                                            {commentsRank.slice(0, 20).map((item,index) => 
                                                <tr
                                                key={index}
                                                >
                                                    <td>
                                                        <div className="row"><p>{index + 1}</p></div>
                                                    </td>
                                                    <td><p>{item.n} {item.ln}</p></td>
                                                    <td><p className="bluetext fw-bolder">{item.c}</p></td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 mb-4 xHidden rank crd postToFeed">
                        <div className="card shadow border-0 p-4">
                            <h4>Top 20 Posters</h4>
                            {/* <div>
                                <button className="btn bluebg rounded-pill">Total</button>
                                <button className="btn btn-light rounded-pill">Shares</button>
                                <button className="btn btn-light rounded-pill">Likes</button>
                                <button className="btn btn-light rounded-pill">Comments</button>
                            </div> */}
                            <div>
                                <table className="table table-hover text-center m-3">
                                    <tbody>
                                            {postToFeedRank.slice(0, 20).map((item,index) => 
                                                <tr
                                                key={index}
                                                >
                                                    <td>
                                                        <div className="row"><p>{index + 1}</p></div>
                                                    </td>
                                                    <td><p>{item.n} {item.ln}</p></td>
                                                    <td><p className="bluetext fw-bolder">{item.f}</p></td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
            <div>
            </div>
        </div>
    </div>
        </div>
    </div>
</div>
    <div>
    </div>
    <footer>
        <nav className="navbar navbar-bottom navbar-light bg-light">
            <div className="container-fluid">
                <a className="navbar-brand" href="https://www.betteragency.co.uk" target="_blank" rel="noreferrer">&copy;2021 Better Agency</a>
            </div>
        </nav>
    </footer>
      </div>
  )
}
}
export default League
