import React, { Component } from "react";
import posts from '../assets/data/posts.json';
import { Form, Button, Card } from "react-bootstrap";
import firebase from "firebase";

var myObject =  JSON.stringify(posts)
var feed = [];
class Admin extends Component{
    constructor() {
    super();
    this.ref = firebase.firestore().collection('users');
    this.state = {
        date : "",
        feat : "",
        lang : "",
        link : "",
        text : ""
        // Image upload URL
    };
  }
   componentDidMount(){
      firebase.firestore().collection("posts")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          console.log(doc.data().date)
        })
      })
   }
   


// posts.forEach( (post) => {
//     feed.push(post)
// })

// var newPost = {
//     "date" : "",
//     "lang" : "",
//     "text" : "",
//     "link" : "",
//     "feat" : false
// }
handleSubmit = (e) => {

  e.preventDefault();
  
  const { date, feat, lang, link, text } = this.state;

  this.ref.add({
        date,
        feat,
        lang,
        link,
        text
    })
    .then((docRef) => {
      this.setState({
        date : "010121",
        feat : false,
        lang : "en",
        link : "linkedin.com",
        text : "Post text pushed"

      });
      this.props.history.push("/")
    })
}

onChange = (e) => {
    switch(e.target.type){
      case "date":
        let date = new Date (e.target.value).toLocaleDateString('en-GB', {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit"
        })
        .split("/")
        .join("")
        this.state.date = date
        break;
      case "text":
        this.state.text = e.target.value
        this.state.lang = e.target.value
        break;
      case "link":
        this.state.link = e.target.value
        break;
      case "featured":
        this.state.feat = e.target.value
        break;
      default:
        break;
    }
}
    render(){
        return( 
          <div>
                    <Card
                        className = "w-50 m-5 mx-auto"
                    >
                    <Card.Body>
                      <h2 className="text-center mb-4">Add post</h2>
                      <Form onSubmit={this.handleSubmit}>
                        <Form.Group id="date">
                          <Form.Label className="mt-3 mb-1">Post Date</Form.Label>
                          <Form.Control 
                          type="date"
                          name="date"
                          required
                          onChange={this.onChange}/>
                        </Form.Group>
                        <Form.Group id="language">
                          <Form.Label className="mt-3 mb-1">Language</Form.Label>
                          <Form.Select 
                            aria-label="Language" 
                            aria-required
                            name="lang"
                            onChange={this.onChange}
                            >
                                <option value="en">English</option>
                                <option value="it">Italian</option>
                                <option value="de">German</option>
                                <option value="fr">French</option>
                          </Form.Select>
                        </Form.Group>
                        <Form.Group id="text">
                          <Form.Label className="mt-3 mb-1">Post Text</Form.Label>
                          <Form.Control 
                          type="text"
                          name="text"
                          required placeholder="Paste the post text here" 
                          onChange={this.onChange}
                          />
                        </Form.Group>
                        <Form.Group id="link">
                          <Form.Label className="mt-3 mb-1">Post Link</Form.Label>
                          <Form.Control 
                          type="text"
                          name="link"
                          required placeholder="Share link for the post"
                          onChange={this.onChange}
                          />
                        </Form.Group>
                        <Form.Group id="featured">
                          <Form.Label className="mt-3 mb-1">Featured</Form.Label>
                            <Form.Select 
                            aria-label="Featured" 
                            aria-required
                            name="featured"
                            onChange={this.onChange}
                            >
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                          </Form.Select>
                          <small id="featuredHelp" className="form-text text-muted">Choose if the post is featured or not</small>
                        </Form.Group>
                        <div
                            className = "text-center"
                        > 
                            <Button className="w-50 rounded-pill bluebg border-0 m-3" type="submit">
                            Publish
                            </Button>
                        </div>
                      </Form>
                    </Card.Body>
                  </Card>
                    <div
                        className = "m-5"
                    >
                        <p>Number of posts = {posts.length}</p>
                        <p>JSON:</p>
                        <div>{myObject}</div>
                    </div>
                </div>
        )}
}
export default Admin