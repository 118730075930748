// import firebase from "firebase/compat/app"
// import "firebase/compat/auth"
// import { initializeApp } from 'firebase/app';
// import { getDatabase } from "firebase/database";

import * as firebase from 'firebase';
// import firestore from 'firebase/firestore'

const app = firebase.initializeApp({
  databaseURL: "https://tek-social-default-rtdb.europe-west1.firebasedatabase.app/",
  apiKey: "AIzaSyCzntOo3YgTSBz74kI1H7Pq3rIyUQQo_gk",
  authDomain: "tek-social.firebaseapp.com",
  projectId: "tek-social",
  storageBucket: "tek-social.appspot.com",
  messagingSenderId: "275521858333",
  appId: "1:275521858333:web:786e9f234c3b6fc3c30ff4",
  measurementId: "G-DSTF4W12HF"
})
// export const db = firebase.firestore()
export const auth = app.auth()
export default app
