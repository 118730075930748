import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link } from "react-router-dom"
import logo from "../assets/img/tek-logo.svg"
import posts from "../assets/img/tek-login-image.png"

export default function ForgotPassword() {
  const emailRef = useRef()
  const { resetPassword } = useAuth()
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setMessage("")
      setError("")
      setLoading(true)
      await resetPassword(emailRef.current.value)
      setMessage("Check your inbox for further instructions")
    } catch {
      setError("Failed to reset password")
    }

    setLoading(false)
  }

  return (
     <div className="container">
        <div className="row ps-5 pe-5 justify-content-center align-items-center">
            <div className="col-md-6 col-sm-12 p-5">
                <img src={logo} alt="Tektronix logo" width="30%"/>
                <h1 className="pt-5 pb-2">Welcome to <span className="bluetext">#TekSocial</span></h1>
                {/* <p>Id Lorem sunt nulla eiusmod cupidatat Lorem elit fugiat occaecat consequat do dolore.</p> */}
                <img src={posts} alt="Tektronix social posts" width="100%"/>
            </div>
            <div className="col-md-6 col-sm-12">
                <>
  <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Password Reset</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          {message && <Alert variant="success">{message}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Button disabled={loading} className="w-100" type="submit">
              Reset Password
            </Button>
          </Form>
          <div className="w-100 text-center mt-3">
            <Link to="/login">Login</Link>
          </div>
        </Card.Body>
      </Card>
                  <div className="w-100 text-center mt-2">
                    Need an account? <Link to="/signup">Sign Up</Link>
                  </div>
                </>
            </div>
        </div>
    </div>
  )
}
