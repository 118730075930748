import React, { Component } from "react"
// import { Link } from 'react-router-dom';
import firebase from '../firebase';
import { Form, Button, Card } from "react-bootstrap"
// import { useAuth } from "../contexts/AuthContext"
// import { useHistory } from "react-router-dom"
import companies from '../assets/data/companies.json'

class Signup2 extends Component {

  constructor() {
    super();
    this.ref = firebase.firestore().collection('users');
    this.state = {
        firstName: '',
        lastName: '',
        email: '',
        type: '',
        company: '',
        team: '',
        UID: '',
        points: 0,
        likes:[],
        shares: [],
        comments: [],
        postToFeed: []
    };
  }
  componentDidMount(){
       const state = this.state
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                state.email = user.email
                state.UID = user.uid
            } else {
                console.log('Logged out')
            }
        });
    }
  onChange = (e) => {
    const state = this.state
    // console.log(e.target.value)
    // console.log(e.target.name)
    state[e.target.name] = e.target.value;
    this.setState(state);
    // if(this.state.type === "Employee"){
    //   document.querySelector('.xHidden').classList.remove('xHidden')
    // }
  }

  onSubmit = (e) => {
    e.preventDefault();

    const { firstName, lastName, email, type, company, team, UID, points, shares, likes, comments, postToFeed } = this.state;

    this.ref.add({
        firstName,
        lastName,
        email,
        type,
        company,
        team,
        UID,
        points,
        shares,
        likes,
        comments,
        postToFeed
    })
    .then((docRef) => {
      this.setState({
        firstName: '',
        lastName: '',
        email: '',
        type: '',
        company: '',
        team: '',
        UID: '',
        points: 0,
        shares: [],
        likes: [],
        comments: [],
        postToFeed: []

      });
      this.props.history.push("/")
    })
    .catch((error) => {
      console.error("Error adding user: ", error);
    });
  }
  
  render() {
    const { firstName, lastName, type, company, team } = this.state;
    return (
      <div className="container">
        <div className="row ps-5 pe-5 justify-content-center align-items-center">
            <div className="col-md-6 col-sm-12 pt-5">
                <>
                  <Card>
                    <Card.Body>
                      <h2 className="text-center mb-4">Complete Your Profile</h2>
                      {/* {error && <Alert variant="danger">{error}</Alert>} */}
                      <Form onSubmit={this.onSubmit}>
                        <Form.Group>
                          <Form.Label className="mt-3 mb-1">First Name</Form.Label>
                          <Form.Control 
                          type="text"
                          value={firstName}
                          name="firstName"
                          onChange={this.onChange}
                          ></Form.Control>
                        </Form.Group>
                                               <Form.Group>
                          <Form.Label className="mt-3 mb-1">Last Name</Form.Label>
                          <Form.Control 
                          type="text"
                          name="lastName"
                          value={lastName}
                          onChange={this.onChange}
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label className="mt-3 mb-1">
                            User Group
                          </Form.Label>
                          <Form.Select 
                          aria-label="Type" 
                          aria-required
                          value={type}
                          name="type"
                          onChange={this.onChange}>
                            <option>Select an option</option>
                            <option value="Employee">Tektronix Employee</option>
                            <option value="Partner">Partner</option>
                            <option value="Service">Service</option>
                            <option value="HSD">HSD</option>
                          </Form.Select>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label className="mt-3 mb-1">
                            Company
                          </Form.Label>
                          
                          <Form.Select
                          type="text" 
                          aria-label="Company" 
                          aria-required
                          id="company"
                          name="company"
                          value={company}
                          onChange={this.onChange}>
                            {companies.map((c,index) =>
                            <option
                            key={index}
                            >{c}</option>
                              )}
                          </Form.Select>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label className="mt-3 mb-1">
                            Team (Tektronix)
                          </Form.Label>
                          <Form.Select 
                          aria-label="Team"
                          name="team"
                          value={team}
                          onChange={this.onChange}>
                              <option>Select your team</option>
                              <option>Tigers - (Satish Mohanram)</option>
                              <option>Lions - (Niklas Olsson)</option>
                              <option>Wolves - (Enrico Zanoio)</option>
                              <option>Bulls - (Bruno Morin)</option>
                              <option>Eagles - (Philippe Pichot)</option>
                          </Form.Select>
                        </Form.Group>
                        <Button className="w-50 rounded-pill bluebg border-0 m-3" type="submit">
                          Complete Sign Up
                        </Button>
                      </Form>
                    </Card.Body>
                  </Card>
                </>
            </div>
        </div>
      </div>
    );
  }
}
export default Signup2
