import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import '../assets/css/main.css'
import logo from "../assets/img/tek-logo.svg"
import posts from "../assets/img/tek-login-image.png"

export default function Login() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setError("")
      setLoading(true)
      await login(emailRef.current.value, passwordRef.current.value)
      history.push("/")
    } catch {
      setError("Failed to log in")
    }

    setLoading(false)
  }

  return (

    <div className="container">
        <div className="row ps-5 pe-5 justify-content-center align-items-center">
            <div className="col-md-6 col-sm-12 p-5">
                <img src={logo} alt="Tektronix logo" width="30%"/>
                <h1 className="pt-5 pb-2">Welcome to <span className="bluetext">#TekSocial</span></h1>
                <p>Your portal for sharing all things Tektronix</p>
                <img src={posts} alt="Tektronix social posts" width="100%"/>
            </div>
            <div className="col-md-6 col-sm-12">
                <>
                  <Card>
                    <Card.Body>
                    <div>
                        <h2>Log in</h2>
                        <span>Login to your #TekSocial account to start sharing</span>
                    </div>
                      {error && <Alert variant="danger">{error}</Alert>}
                      <Form onSubmit={handleSubmit}>
                        <Form.Group id="email">
                          <Form.Label>Email</Form.Label>
                          <Form.Control type="email" ref={emailRef} required />
                        </Form.Group>
                        <Form.Group id="password">
                          <Form.Label>Password</Form.Label>
                          <Form.Control type="password" ref={passwordRef} required />
                        </Form.Group>
                        <div className="text-center">
                        <Button disabled={loading} className="w-50 rounded-pill bluebg border-0 m-3" type="submit">
                          Log In
                        </Button>
                        </div>
                      </Form>
                      <div className="w-100 text-center mt-3">
                        <Link to="/forgot-password">Forgot Password?</Link>
                      </div>
                    </Card.Body>
                  </Card>
                  <div className="w-100 text-center mt-2">
                    Need an account? <Link to="/signup">Sign Up</Link>
                  </div>
                </>
            </div>
        </div>
    </div>
  )
}
