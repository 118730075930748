import React, { Component } from "react"
import { Link} from "react-router-dom"
import firebase from "firebase"

import logo from "../assets/img/tek-logo.svg"
import list from "../assets/img/fa/list-alt.svg"
import trophy from "../assets/img/fa/trophy.svg"
// import user from "../assets/img/fa/user-alt.svg"
import faqIcon from "../assets/img/fa/comments.svg"
import signOut from "../assets/img/fa/sign-out-alt.svg"
import faqVideo from "../assets/video/Tek_FAQs_video_v2.mp4"

function showAnswer(e){
    document.querySelectorAll('.answer').forEach(function(item){
        item.classList.add('xHidden')
    })
    document.querySelectorAll('.btn').forEach(function(item){
        item.classList.remove('bluebg');
        item.classList.add('bg-secondary')
    })
    switch(e.target.value){
        case '1':
            document.querySelector('.answer.one').classList.remove('xHidden')
            e.target.classList.remove('bg-secondary')
            e.target.classList.add('bluebg')
            break;
        case '2':
            document.querySelector('.answer.two').classList.remove('xHidden')
            e.target.classList.remove('bg-secondary')
            e.target.classList.add('bluebg')
            break;
        case '3':
            document.querySelector('.answer.three').classList.remove('xHidden')

            e.target.classList.remove('bg-secondary')
            e.target.classList.add('bluebg')
            break;
        case '4':
            document.querySelector('.answer.four').classList.remove('xHidden')
            e.target.classList.remove('bg-secondary')
            e.target.classList.add('bluebg')
            break;
        case '5':
            document.querySelector('.answer.five').classList.remove('xHidden')
            e.target.classList.remove('bg-secondary')
            e.target.classList.add('bluebg')
            break;
        case '6':
            document.querySelector('.answer.six').classList.remove('xHidden')
            e.target.classList.remove('bg-secondary')
            e.target.classList.add('bluebg')
            break;
        case '7':
            document.querySelector('.answer.seven').classList.remove('xHidden')
            e.target.classList.remove('bg-secondary')
            e.target.classList.add('bluebg')
            break;
        case '8':
            document.querySelector('.answer.eight').classList.remove('xHidden')
            e.target.classList.remove('bg-secondary')
            e.target.classList.add('bluebg')
            break;
        case '9':
            document.querySelector('.answer.nine').classList.remove('xHidden')
            e.target.classList.remove('bg-secondary')
            e.target.classList.add('bluebg')
            break;
        case '10':
            document.querySelector('.answer.ten').classList.remove('xHidden')
            e.target.classList.remove('bg-secondary')
            e.target.classList.add('bluebg')
            break;
        default:
            console.log('no answers')
    }
}
class faq extends Component{
    constructor() {
        super();
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            type: '',
            company: '',
            team: '',
            UID: '',
            points: '',
            shares: [],
            likes: [],
            comments: [],
            postToFeed: [],
            postToFeedRank: [],
            totalPoints:0,
            mostPointsName:'',
            mostPoints: 0,
            mostLikesName:'',
            mostLikes: 0,
            mostSharesName: '',
            mostShares: 0,
            mostCommentsName: '',
            mostComments: 0,
            pointsRank: [],
            sharesRank: [],
            likesRank: [],
            commentsRank: []

        };
    }
 componentDidMount(){
       const state = this.state
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                state.UID = user.uid
            } else {
                console.log('Logged out')
            }
        });
        // User points
        firebase.firestore().collection("users")
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                if (doc.data().UID === state.UID){
                    this.setState({              
                      points: doc.data().points
                    });
                }
            });
        })
        .catch((error) => {
            console.log("Error getting documents: ", error);
        });
        // User points
        firebase.firestore().collection("users")
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                if (doc.data().UID === state.UID){
                    this.setState({              
                      points: doc.data().points
                    });
                }
            });
        })
        // Check if user type is Partner
        firebase.firestore().collection("users")
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                if (doc.data().UID === state.UID){
                    this.setState({              
                      type: doc.data().type == "Partner"
                    });
                }
            });
        })
    }

render(){
    const { points } = this.state;
    const { type } = this.state;
    let leagueTable;

    if (type) {
      leagueTable = '';
    } else {
      leagueTable = <li className="nav-item">
                        <Link to="/league-table" className="nav-link align-middle px-0 text-light mb-2">
                            <i><img src={trophy} alt="L"/></i> <span className="ms-3 d-none d-sm-inline">League Table</span>
                        </Link>
                    </li>;
    }
        return (
<div>
        <header>
        <nav className="navbar navbar-light bg-light border-bottom">
            <div className="container-fluid">
                    <img className="navbar-brand m-2" src={logo} alt="Tektronix logo" height="40px"/>
            </div>
        </nav>
    </header>
    <div className="container-fluid">
    <div className="row flex-nowrap">
        <div className="col-2 col-sm-4 col-lg-2 px-sm-2 px-0 sidebar">
            <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                    <li className="nav-item">
                      <Link to="/" className="nav-link align-middle text-light px-0 mb-2">
                            <i><img src={list} alt="F"/></i> <span className="ms-3 d-none d-sm-inline">Your Feed</span>
                       </Link>
                    </li>
                    {leagueTable}
                    {/* <li className="nav-item">
                        <a href="/key-accounts.html" className="nav-link align-middle px-0 text-light mb-2">
                            <i><img src={user} alt="P"/></i> <span className="ms-3 d-none d-sm-inline">Partner Feeds</span>
                        </a>
                    </li> */}
                    <li className="nav-item">
                        <Link to="/faq" className="nav-link align-middle px-0 fw-bolder bluetext mb-2">
                            <i><img src={faqIcon}alt="F" /></i> <span className="ms-3 d-none d-sm-inline">FAQs</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/login" className="nav-link align-middle px-0 text-light mb-2">
                            <i><img src={signOut} alt="L"/></i> <span className="ms-3 d-none d-sm-inline">Log Out</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
        <div className="col-10 col-sm-8 col-lg-10 p-5">
            <div className="container-fluid p-0">
                            <h1><span className="bluetext">#TekSocial</span> FAQs</h1>
            </div>
            <div className="container-fluid p-0">
                <div className="col py-3">
                    <div className="row">
                                <div className="col mb-3">
                                    <div className="answer one">
                                        <h2>Why should I use the #TekSocial platform?</h2>
                                        <p>
                                        The #TekSocial platform is the fastest way for you to engage with Tektronix’s exciting social media content whilst accruing points and having friendly competition with your colleagues. At the end of each quarter, the person with the most points will be given a prize!
                                        The reason you should engage with Tek’s social content is that by liking, commenting and sharing you are increasing the reach of our social channels. These posts and messages will appear in your network and in the networks of 100s of other colleagues. This increases the chances of more people engaging with the brand and getting our stories heard. With enough internal engagement, we can even get our content seen by people unrelated to Tektronix – making us a global social media force in the industry. It all starts here!
                                        </p>
                                    </div>
                                    <div className="answer xHidden two">
                                        <h2>How do I share a post?</h2>
                                        <p>
                                        To share a specific post, find it on your dashboard. This can be under the ‘Featured posts’ section or beneath it. Once you’ve found the post you wish to share, simply hit the ‘share’ button located on the bottom right of the post, next to Like and Comment. This will direct you to the post in the LinkedIn platform, where you’ll be able to share the post as normal.
                                        </p>
                                        <p>
                                        In the case of sharing content from or as a partner of Tektronix, the content can be amended as required. For example, a URL for a landing page can be updated or the post content itself, to better reflect your message.
                                        </p>
                                    </div>
                                    <div className="answer xHidden three">
                                        <h2>How many points do I get?</h2>
                                        <p>
                                        You get 10 points for a Comment, 20 points for a Like and 20 points for a Share. You can achieve a maximum of 50 points per post. These are tallied and detailed on the League Table section of the platform, where you can keep track of your score and compare to others. 
                                        </p>
                                    </div>
                                    <div className="answer xHidden four">
                                        <h2>How do I get to Number One?</h2>
                                        <p>
                                        Simple. Like, Share, and Comment on more posts! The more activity you do on the #TekSocial Platform, the more points you’ll gain and the higher your place will be on the leaderboard. To get to Number One, you just need to engage with more posts than your colleagues.
                                        </p>
                                    </div>
                                    <div className="answer xHidden five">
                                        <h2>How do I delete a post?</h2>
                                        <p>
                                        To delete a Like, Comment or Share, you’ll need to access your LinkedIn profile. Scroll down to see your recent activity, where you’ll be able to see all of your engagements. From there you can remove an action as desired. This will not reflect in your points total.
                                        </p>
                                    </div>
                                    <div className="answer xHidden six">
                                        <h2>How can I update my profile?</h2>
                                        <p>
                                        Your profile is locked-in upon sign up and there is no way to change it within the platform. If you wish to update some information, you can create a new account or contact support using the live chat feature.
                                        </p>
                                    </div>
                                    <div className="answer xHidden seven">
                                        <h2>How do I change my password?</h2>
                                        <p>
                                        If you know your logins but wish to update your password, Log Out of the platform. At the login screen, go to ‘Forgot Password’. This will allow you to perform a password reset on your account. If you have lost or forgotten your password entirely, you can use this same method to recover your account.
                                        </p>
                                    </div>
                                    <div className="answer xHidden eight">
                                        <h2>How do I find specific posts?</h2>
                                        <p>
                                        Posts are shown in chronological order, so (aside from the featured section) the posts at the top of the feed are the most recently published. To find specific posts, scroll down the feed relative to the date they were published on LinkedIn.
                                        </p>
                                    </div>
                                    <div className="answer xHidden nine">
                                        <h2>How do I contact support?</h2>
                                        <p>
                                        If you are experiencing issues with the #TekSocial platform contact support using the live chat feature in the bottom right-hand corner of the dashboard. Once a query is sent, a member of our support team will be in touch. The live chat function is only open 9:00 - 17:00 GMT, Monday to Thursday, and 9:00 - 15:00 on Friday.
                                        </p>
                                    </div>
                                   <div className="answer xHidden ten">
                                        <h2>Do I need to include anything specific in my posts?</h2>
                                        <p>
                                        Yes. When you share or comment on a post, be sure to include the hashtags #TekSocial and #Employee so everyone can see that you’re associated with Tektronix. This is a legal requirement, but will also fill up your hashtag numbers!
                                        </p>
                                    </div>
                                </div>
                            </div>
                                <div className="row">

                                    <div className="col-12">
                                        <ul className="p-0 text-center faq">
                                            <li>
                                                <button type="button" className="btn bluebg w-100" value="1" onClick={showAnswer}>
                                                Why should I use the #TekSocial platform?
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" className="btn bg-secondary w-100" value="2" onClick={showAnswer}>
                                                How do I share a post?
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" className="btn bg-secondary w-100" value="3" onClick={showAnswer}>
                                                How many points do I get?
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" className="btn bg-secondary w-100" value="4" onClick={showAnswer}>
                                                How do I get to Number One?
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" className="btn bg-secondary w-100" value="5" onClick={showAnswer}>
                                                How do I delete a post?
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" className="btn bg-secondary w-100" value="6" onClick={showAnswer}>
                                                How can I update my profile?
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" className="btn bg-secondary w-100" value="7" onClick={showAnswer}>
                                                How do I change my password?
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" className="btn bg-secondary w-100" value="8" onClick={showAnswer}>
                                                How do I find specific posts?
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" className="btn bg-secondary w-100" value="9" onClick={showAnswer}>
                                                How do I contact support?
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" className="btn bg-secondary w-100" value="10" onClick={showAnswer}>
                                                Do I need to add anything specific in my posts?
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-12 text-center mt-3">
                                        <video className="pb-5 embed-responsive-item faqs-video" width="100%" controls>
                                            <source src={faqVideo} type="video/mp4"></source>
                                            Your browser does not support this video. Please try another browser.
                                        </video>
                                    </div>
                                </div>
                                
                        </div>
            </div>
        </div>
    </div>
</div>
    
    <footer>
        <nav className="navbar navbar-bottom navbar-light bg-light">
            <div className="container-fluid">
                <span>BetterSharing v0.9.7(alpha)</span><a className="navbar-brand" href="https://www.betteragency.co.uk" target="_blank" rel="noreferrer">&copy;2021 Better Agency</a>
            </div>
        </nav>
    </footer>
      </div>
        )
    }
}
export default faq