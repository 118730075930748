import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { useHistory } from "react-router-dom"
import logo from "../assets/img/tek-logo.svg"
import posts from "../assets/img/tek-login-image.png"
// import companies from '../assets/data/companies.json'

export default function Signup() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { signup } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  async function handleSubmit(e) {
    e.preventDefault()

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match")
    }

    try {
      setError("")
      setLoading(true)
      await signup(emailRef.current.value, passwordRef.current.value)
      history.push("/continue-signup")
    } catch {
      setError("Failed to create an account")
    }

    setLoading(false)
  }

  return (

    <div className="container">
        <div className="row ps-5 pe-5 justify-content-center align-items-center">
            <div className="col-md-6 col-sm-12 p-5">
                <img src={logo} alt="Tektronix logo" width="30%"/>
                <h1 className="pt-5 pb-2">Sign up to <span className="bluetext">#TekSocial</span></h1>
                <p>Fill out your details to get sharing!</p>
                <img src={posts} alt="Tektronix social posts" width="100%"/>
            </div>
            <div className="col-md-6 col-sm-12 pt-5">
                <>
                  <Card>
                    <Card.Body>
                      <h2 className="text-center mb-4">Sign Up</h2>
                      {error && <Alert variant="danger">{error}</Alert>}
                      <Form onSubmit={handleSubmit}>
                        <Form.Group id="email">
                          <Form.Label className="mt-3 mb-1">Company Email</Form.Label>
                          <Form.Control type="email" ref={emailRef} required />
                        </Form.Group>
                        <Form.Group id="password">
                          <Form.Label className="mt-3 mb-1">Password (must be longer than 6 characters)</Form.Label>
                          <Form.Control type="password" ref={passwordRef} required />
                        </Form.Group>
                        <Form.Group id="password-confirm">
                          <Form.Label className="mt-3 mb-1">Password Confirmation</Form.Label>
                          <Form.Control type="password" ref={passwordConfirmRef} required />
                        </Form.Group>
                        {/* <Form.Group>
                          <Form.Label className="mt-3 mb-1">
                            Type
                          </Form.Label>
                          <Form.Select aria-label="Type" aria-required>
                            <option>Select an option</option>
                            <option value="Employee">Tektronix Employee</option>
                            <option value="Partner">Partner</option>
                            <option value="Service">Service</option>
                            <option value="HSP">HSP</option>
                          </Form.Select>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label className="mt-3 mb-1">
                            Company
                          </Form.Label>
                          
                          <Form.Select
                          type="text" 
                          aria-label="Company" 
                          aria-required>
                            {companies.map((company,index) =>
                            <option
                            key={index}
                            >{company}</option>
                              )}
                          </Form.Select>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label className="mt-3 mb-1">
                            Team (Tektronix)
                          </Form.Label>
                          <Form.Select aria-label="Team">
                              <option>Select your team</option>
                              <option>Benelux</option>
                              <option>Israel</option>
                              <option>MEA</option>
                              <option>DACH</option>
                              <option>India</option>
                          </Form.Select>
                        </Form.Group> */}
                        <Button disabled={loading} className="w-50 rounded-pill bluebg border-0 m-3" type="submit">
                          Continue
                        </Button>
                      </Form>
                    </Card.Body>
                  </Card>
                  {/* <div className="w-100 text-center mt-2">
                    Need an account? <Link to="/signup">Sign Up</Link>
                  </div> */}
                </>
            </div>
        </div>
    </div>
  )
}
