import React, { Component } from "react"
import { Button, Card} from "react-bootstrap"
import '../assets/css/main.css'
import posts from '../assets/data/posts.json'
import logo from "../assets/img/tek-logo.svg"
import firebase from "firebase"

var featuredPosts = []
var otherPosts = []

var enFeatured = []
var enOther = []
var itFeatured= []
var itOther = []
var deFeatured= []
var deOther = []
var frFeatured= []
var frOther = []
var ruFeatured= []
var ruOther = []
var inFeatured= []
var inOther = []
posts.forEach(function(item){
    switch(item.lang){
        case 'en':
                if(item.featured ===  true){
                    enFeatured.push(item)
                }else{
                    enOther.push(item)
                }
            break;
        case 'it':
                if(item.featured ===  true && itFeatured.length < 4){
                    itFeatured.push(item)
                }else{
                    itOther.push(item)
                }
            break;
        case 'de':
                if(item.featured ===  true && deFeatured.length < 4){
                    deFeatured.push(item)
                }else{
                    deOther.push(item)
                }
            break;
        case 'fr':
                if(item.featured ===  true && frFeatured.length < 4){
                    frFeatured.push(item)
                }else{
                    frOther.push(item)
                }
            break;
        case 'ru':
                if(item.featured ===  true && ruFeatured.length < 4){
                    ruFeatured.push(item)
                }else{
                    ruOther.push(item)
                }
            break;
        case 'in':
                if(item.featured ===  true && inFeatured.length < 4){
                    inFeatured.push(item)
                }else{
                    inOther.push(item)
                }
            break;
            default:
                console.log('lang sort error')
    }
});

class Feed extends Component{
    constructor() {
        super();
        this.date = new Date().toLocaleDateString("en-GB")
        this.db = firebase.firestore()
        this.docId = ''
        this.activityDocId = ''
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            type: '',
            company: '',
            team: '',
            UID: '',
            points: '',
            postToFeed: [],
            shares:[],
            likes: [],
            comments: [],
            postToFeed: [],
            lang: 'en'
        };
    }
 componentDidMount(){
       const state = this.state
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                state.UID = user.uid
            } else {
                console.log('Logged out')
            }
        });
        firebase.firestore().collection("users")
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                if (doc.data().UID === state.UID){
                    this.docId = doc.id
                    this.setState({
                        firstName: doc.data().firstName,
                        lastName: doc.data().lastName,
                        email: doc.data().email,
                        type: doc.data().type,
                        company: doc.data().company,
                        team: doc.data().team,
                        UID: doc.data().UID,
                        points: doc.data().points,
                        shares: doc.data().shares,
                        likes: doc.data().likes,
                        comments: doc.data().comments,
                        postToFeed: doc.data().postToFeed,
                    });
                }
            });
        })
        .catch((error) => {
            console.log("Error getting documents: ", error);
        });

        featuredPosts = enFeatured.reverse()
        otherPosts = enOther.reverse()

        itFeatured.reverse()
        itOther.reverse()

        deFeatured.reverse()
        deOther.reverse()
        
        frFeatured.reverse()
        frOther.reverse()

        inFeatured.reverse()
        inOther.reverse()
    }

    updatePoints = (p, t) => {
        const state = this.state
        var oldPoints = state.points
        var likes = state.likes
        var shares = state.shares
        var comments = state.comments
        var postToFeed = state.postToFeed
        var newPoints = oldPoints + p
        switch(t){
            case 'like':
                likes.push(new Date().toLocaleDateString("en-GB"))
                this.setState({
                        like: likes
                    })
                break;
            case 'share':
                    shares.push(new Date().toLocaleDateString("en-GB"))
                    this.setState({
                        share: shares
                    })
                break;
            case 'comment':
                    comments.push(new Date().toLocaleDateString("en-GB"))
                    this.setState({
                        comments: comments
                    })
                break;
            case 'post':
                    postToFeed.push(new Date().toLocaleDateString("en-GB"))
                    this.setState({
                        postToFeed: postToFeed
                    })
                break;
            default: console.log('Share type error')
        }

        this.setState({
            points: newPoints,
            likes: likes,
            shares: shares,
            comments: comments,
            postToFeed: postToFeed
        })

        // update activity to user
        var user = this.db.collection("users").doc(this.docId);

        return user.update({
            points: newPoints,
            shares: shares,
            likes: likes,
            comments: comments,
            postToFeed: postToFeed

            
        })
        .catch((error) => {
            console.error("Error updating document: ", error);
        });
                
    }
    // like = (e) => {
    //     this.updatePoints(20, 'like')
    //     e.target.classList.add('bg-secondary')
    //     e.target.classList.add('disabled')
    //     e.target.classList.remove('bluebg')
    // }
    share = (e) => {
      // Update points for share
        this.updatePoints(10, 'share')
        // e.target.classList.add('bg-secondary')
        // e.target.classList.add('disabled')
        // e.target.classList.remove('bluebg')
    }
    // comment = (e) => {
    //     this.updatePoints(10, 'comment')
    //     e.target.classList.add('bg-secondary')
    //     e.target.classList.add('disabled')
    //     e.target.classList.remove('bluebg')
    // }
    post = (e) => {
      // Update points for share
        this.updatePoints(20, 'post')
        // e.target.classList.add('bg-secondary')
        // e.target.classList.add('disabled')
        // e.target.classList.remove('bluebg')
    }
    langFilter = (e) => {
        // style language buttons
        document.querySelectorAll('.lang button').forEach(function(button){
            button.classList.remove('bluebg')
            button.classList.add('bg-secondary')
        })
        e.target.classList.remove('bg-secondary')
        e.target.classList.add('bluebg')

        // sort posts
        switch(e.target.value){
            case 'en':
                featuredPosts = enFeatured
                otherPosts    = enOther
                break;
            case 'it':
                featuredPosts = itFeatured
                otherPosts    = itOther
                break;
            case 'de':
                featuredPosts = deFeatured
                otherPosts    = deOther
                break;
            case 'fr':
                featuredPosts = frFeatured
                otherPosts    = frOther
                break;
            case 'ru':
                featuredPosts = ruFeatured
                otherPosts    = ruOther
                break;
            case 'in':
                featuredPosts = inFeatured
                otherPosts    = inOther
                break;
            default:
                console.log('lang render error')
        }
        this.setState({
            lang : e.target.value
        })

    }
    


render(){
    const { 
            lang
        } = this.state;

    function FormatCodeToDate(props) {       

        // Temp Solution due to date in feed not being stored as a timestamp
        const initialDate = props.dateToChange;        
        const dateDay  = initialDate.substring(0,2);
        const dateMonth = initialDate.substring(2,4);
        const dateYear = initialDate.substring(4,6);
        return <span>{dateDay}/{dateMonth}/{dateYear}</span>; 

    }

    return (
    <>  
        <div className="mb-3 lang">
            <Button className="rounded-pill bluebg border-0 m-1" value="en" onClick={this.langFilter}>English</Button>
            {/* <Button className="rounded-pill bg-secondary border-0 m-1" value="it" onClick={this.langFilter}>Italian</Button>*/}
            {/* <Button className="rounded-pill bg-secondary border-0 m-1" value="de" onClick={this.langFilter}>German</Button>*/}
            {/* <Button className="rounded-pill bg-secondary border-0 m-1" value="fr" onClick={this.langFilter}>French</Button>*/}
            {/* <Button className="rounded-pill bg-secondary border-0 m-1" value="ru" onClick={this.langFilter}>Russian</Button> */}
            {/* <span>lang = { lang }</span> */}
            {/*<Button className="rounded-pill bg-secondary border-0 m-1" value="in" onClick={this.langFilter}>Indian</Button> */}
        </div>
        <div className="featured-container container darkbluebg">
            <h2 className="p-3">Featured posts</h2>
            <div className="row justify-content-center pb-3 mb-5">
                {featuredPosts.map((post,index) =>
                <div
                id={"f" + index}
                className = "featured filter col-lg-6"
                // className="col-3 m-1 ps-0 pe-0"
                key={index}
                value={post.lang}
                >
                    <Card
                    className={post.lang + " text-dark featured p-3"}
                    >
                        <div className="row justify-content-center align-items-center">
                            <div className="col-md-6">
                        <img 
                            src={logo} 
                            alt="Tektronix logo"
                            // className="m-2 ms-0"
                            className="logo"
                        />
                        <div>
                            <strong><FormatCodeToDate dateToChange={post.date} /></strong>
                            <p>
                                {post.text}
                            </p>

                    
                        </div>
                            </div>
                            <div className="col-md-6 mb-3 mb-md-0">
                                <img
                                    // src={post.image + post.date + post.lang + ".jpeg"}
                                    src= {"/feed/" + post.date + post.lang +".jpeg"}
                                    alt={post.title + post.lang}
                                    // className="mb-2 card-img-top p-0"
                                    className="featured-image"

                                /> 
                            </div>
                            <div className="col-12 mt-3">
                                <div className="text-center">
                                        {/*
                                        <Button
                                            className="rounded-pill bluebg border-0 m-1"
                                            href={post.action}
                                            target="_blank"
                                            rel="noreferrer"
                                            onClick={this.like}
                                        >Like</Button>

                                        <Button
                                            className="rounded-pill bluebg border-0 m-1"
                                            href={post.action}
                                            target="_blank"
                                            rel="noreferrer"
                                            onClick={this.comment}
                                        >Comment</Button>

                                        */}
                                        <Button
                                            className="rounded-pill bluebg border-0 m-1 feed-cta"
                                            href={post.action}
                                            target="_blank"
                                            rel="noreferrer"
                                            onClick={this.share}
                                        >
                                        Share</Button>

                                        <Button
                                            className="rounded-pill bluebg border-0 m-1 feed-cta"
                                            href={post.action}
                                            href={"https://www.linkedin.com/shareArticle?mini=true&url=" + post.action +""}
                                            target="_blank"
                                            rel="noreferrer"
                                            onClick={this.post}
                                        >
                                        Post</Button>
                                </div>
                            </div>
                        </div>
                    </Card>
                    {/* <Modal id={index}>

                    </Modal>
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" id={index}>
                    
                    </div> */}
                </div>            
                    )}
                
            </div>
        </div>

        <div className="container-fluid p-lg-5">
            <div className="row p-lg-5 justify-content-center d-flex align-items-stretch">
                {otherPosts.map((post,index) =>
                <div
                className="filter col-lg-6 mt-1 mb-1"
                key={index}
                value={post.lang}
                >
                    <Card
                    className={post.lang}
                    >

                        <img
                                // src={post.image + post.date + post.lang + ".jpeg"}
                                src= {"/feed/" + post.date + post.lang +".jpeg"}
                                alt={post.title + post.lang}
                                className="mb-2 card-img-top p-0"

                            /> 
                        <div 
                        className="ps-3 pe-3 pb-3 bg-white"
                        >
                        <img 
                            src={logo} 
                            alt="Tektronix logo"
                            height="50rem"
                            width="100rem"
                            className="m-2 ms-0"
                        />
                        <div>
                            <strong><FormatCodeToDate dateToChange={post.date} /></strong>
                        </div>
                        <div>
                            <p
                            className="feed">
                                {post.text}
                            </p>

                    
                        </div>
                        <div className="text-center">
                            {/*
                            <Button
                                className="rounded-pill bluebg border-0 m-1"
                                href={post.action}
                                target="_blank"
                                rel="noreferrer"
                                onClick={this.like}
                            >Like</Button>

                            <Button
                                className="rounded-pill bluebg border-0 m-1"
                                href={post.action}
                                target="_blank"
                                rel="noreferrer"
                                onClick={this.comment}
                            >Comment</Button>
                            */}

                            <Button
                                className="rounded-pill bluebg border-0 m-1 feed-cta"
                                href={post.action}
                                target="_blank"
                                rel="noreferrer"
                                onClick={this.share}
                            >
                            Share</Button>
                            {/* <Button
                                className="rounded-pill bluebg border-0 m-1"
                                rel="noreferrer"
                                data-bs-toggle="modal" 
                                data-bs-target={post.id}
                                onClick={handleShow}
                            >
                                Share options
                            </Button> */}
                            <Button
                                className="rounded-pill bluebg border-0 m-1 feed-cta"
                                href={post.action}
                                href={"https://www.linkedin.com/shareArticle?mini=true&url=" + post.action +""}
                                target="_blank"
                                rel="noreferrer"
                                onClick={this.post}
                            >
                            Post</Button>
                        </div>
                        </div>
                    </Card>
                    {/* <Modal id={index}>

                    </Modal>
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" id={index}>
                    
                    </div> */}
                </div>            
                    )}
                
            </div>
        </div>
    </>
)}
}
export default Feed